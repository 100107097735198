<template>
  <article
    v-if="banner"
    :class="['product-banner', `product-banner--${contentPosition}`]"
  >
    <component
      :is="getElement"
      class="product-banner__link"
      :to="banner?.File?.Link"
    >
      <div class="product-banner__visual">
        <picture
          v-if="banner?.File?.Media?.url && banner?.File?.Media?.mime?.includes('image')"
          class="banner__picture"
        >
          <source
            :srcset="`${banner?.File?.Media?.url?.replaceAll(' ', '%20')}`"
            media="(min-width: 768px)"
          >
          <source
            :srcset="`${banner?.File?.MediaMobile?.url?.replaceAll(' ', '%20') || banner?.File?.Media?.url?.replaceAll(' ', '%20')}`"
          >
          <img
            class="product-banner__image"
            :src="banner?.File?.Media?.url?.replaceAll(' ', '%20')"
            :alt="banner?.File?.Media?.alternativeText || banner?.Name || ''"
            :width="banner?.File?.Media?.width"
            :height="banner?.File?.Media?.height"
            :loading="lazy ? 'lazy' : 'eager'"
            :fetchpriority="lazy ? 'low' : 'high'"
            :decoding="lazy ? 'async' : 'sync'"
          >
        </picture>
        <div
          v-else-if="banner?.File?.Media?.url && banner?.File?.Media?.mime?.includes('video')"
          class="product-banner__video"
        >
          <UiVideo
            v-if="banner?.File?.Media?.url"
            :class="{ 'mq--desktop-plus': banner?.File?.Media?.url && banner?.File?.MediaMobile?.url }"
            :src="banner?.File?.Media?.url"
            :alt="banner?.File?.Media?.alternativeText || banner?.Name"
            :width="banner?.File?.Media?.width"
            :height="banner?.File?.Media?.height"
            :loading="lazy ? 'lazy' : 'eager'"
            :fetchpriority="lazy ? 'auto' : 'high'"
            :controls="false"
            autoplay
            muted
            loop
          />
          <UiVideo
            v-if="banner?.File?.MediaMobile?.url && banner?.File?.MediaMobile?.mime?.includes('video')"
            class="mq--mobile-phablet"
            :src="banner?.File?.MediaMobile?.url"
            :alt="banner?.File?.MediaMobile?.alternativeText || banner?.Name"
            :width="banner?.File?.MediaMobile?.width"
            :height="banner?.File?.MediaMobile?.height"
            :loading="lazy ? 'lazy' : 'eager'"
            :fetchpriority="lazy ? 'auto' : 'high'"
            :controls="false"
            autoplay
            muted
            loop
          />
        </div>
      </div>

      <div class="product-banner__content">
        <div
          v-if="banner?.Title"
          class="product-banner__heading heading heading--4"
        >
          {{ banner.Title }}
        </div>

        <p
          v-if="banner?.Description"
          class="product-banner__description"
        >
          {{ banner.Description }}
        </p>

        <component
          :is="getButtonElement"
          v-if="banner?.ButtonText"
          class="product-banner__button"
          :to="banner.File.Link"
        >
          <span>{{ banner?.ButtonText }}</span>
          <IconCssArrow2 class="icon icon--arrow-right" />
        </component>
      </div>
    </component>
  </article>
</template>

<script setup lang="ts">
import { AppLink } from '#components'

const props = defineProps<{
  banner: any
}>()

const lazy = true

const getElement = computed(() => {
  if (props?.banner?.File?.Media?.mime?.includes('video')) {
    return 'div'
  }

  if (props?.banner?.File?.Link) {
    return AppLink
  }

  return 'div'
})

const getButtonElement = computed(() => {
  if (props?.banner?.File?.Media?.mime?.includes('video')) {
    return AppLink
  }

  return 'span'
})

const contentPosition = computed(() => {
  const cp = props.banner?.ContentPosition

  if (['topleft', 'top', 'topright', 'left', 'right', 'bottomleft', 'bottom', 'bottomright'].includes(cp)) {
    return cp
  }

  return 'center'
})
</script>

<style src="~layers/app/components/Product/Banner.css" />
